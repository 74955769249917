import React, { useState } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"

const Container = styled.div`
  width: 100%;
  background: #ccc;
`

const Content = styled.div`
  max-width: 1400px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 3rem 2rem 0 2rem;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 0 12rem 0;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  background: #ccc;
  margin: 0 auto;
  @media (max-width: 768px) {
    margin: 0 1.5rem;
  }
`

export const Input = styled.input`
  width: 100%;
  margin: 1rem 2rem 1rem 0;
  font-size: 1.5rem;
  font-family: "Open Sans";
  border: none;
  background: #eee;
  height: 3.5rem;
  border-radius: 5px;
  &::placeholder {
    text-align: center;
    font-weight: 300;
  }
`

const Fieldset = styled.fieldset`
  padding: 2rem;
  font-size: 1.5rem;
  border: 1px solid #f26a28;
  font-weight: 300;
`

const H2 = styled.h1`
  font-family: "Open Sans";
  text-align: center;
  text-transform: uppercase;
  color: #f26a28;
  margin: 3rem 0 0 0;
`

const Button = styled.input`
  border: none;
  border-radius: 10px;
  color: white;
  background: #f26a28;
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 2rem;
  margin: 2rem auto;
  width: 20rem;
  padding: 0.9rem;
  text-transform: uppercase;
  display: block;
  &:hover {
    background: black;
    opacity: 0.8;
    border: 2px solid #f26a28;
    color: white;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`

const P = styled.p`
  font-family: "Open Sans";
  font-weight: 300;
  text-align: center;
`

const Phone = styled(P)`
  font-size: 1.5rem;
  font-weight: 600;
  color: #f26a28;
`
const StatusDisplay = styled.div`
  max-width: 900px;
  display: flex;
  flex-direction: column;
  margin: 3rem auto 6rem auto;
`

const ListItem = styled.ol`
  padding: 0 1.5rem;
`

const List = styled.li`
  width: 100%;
  font-weight: 300;
  font-size: 1.2rem;
  margin: 1.2rem auto;
  padding-bottom: 0.6rem;
  border-bottom: 2px solid #54a5d5;
  list-style-position: inside;
  line-height: 1.5;
`

const RepairStatus = () => {
  const [button, setButton] = useState("")
  const [btnTxt, setBtnTxt] = useState("Prüfen")
  const [customerStatus, setCustomerStatus] = useState([""])
  const [isSent, setIsSent] = useState(false)

  const { register, handleSubmit, errors } = useForm()

  const onSubmit = async data => {
    setButton("value")
    setBtnTxt("warten")
    await fetch("https://email-server-liart.vercel.app/statusCustomer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(resultData => setCustomerStatus(resultData))
      .then(() => setIsSent(true))
      .catch(() => console.log("There was an error, please try again"))
  }

  return (
    <Container>
      <H2>Reparaturstatus</H2>
      <P>Ihr Gerät aktuelle Informationen</P>
      <Content>
        {isSent ? (
          <StatusDisplay>
            <ListItem>
              {customerStatus.map((item, i) => {
                return <List key={i}>{item.text} </List>
              })}
            </ListItem>
          </StatusDisplay>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <P>
              Um den Status Ihrer Reparatur abzufragen, tragen Sie bitte Ihre
              <b> Auftragsnummer</b> in das unten vorgegebene Feld ein.
            </P>
            <Fieldset>
              <legend style={{ textAlign: "right" }}>Ihre Daten:</legend>
              <label htmlFor="number">
                Auftragnummer:
                {errors.number && (
                  <span style={{ color: "red" }}>
                    {" "}
                    6-stellige Zahl ist erforderlich
                  </span>
                )}
                <Input
                  type="number"
                  placeholder="05614314"
                  name="number"
                  ref={register({ required: true, maxLength: 6, minLength: 6 })}
                />
              </label>

              <Button
                type="submit"
                value={btnTxt}
                disabled={button ? true : ""}
              />
            </Fieldset>
          </Form>
        )}
        <P style={{ padding: "0 .5rem 0 .5rem" }}>
          Bitte kontaktieren Sie uns, wenn die genannten Informationen unklar
          sind oder Ihr Problem nicht lösen konnten.
        </P>
        <Phone>030 / 856 123 49</Phone>
      </Content>
    </Container>
  )
}

export default RepairStatus
