import React from "react";
import Page from "../components/common/page";
import { ContentRow } from "../components/common/shared-components/layouts";
import RepairStatus from "../components/page-components/forms/status";

const CONTENT_ROWS = [[<RepairStatus />]];

const Status = () => {
  return (
    <Page title="repairStatus">
      {CONTENT_ROWS.map((components, key) => (
        <ContentRow key={key} components={components} />
      ))}
    </Page>
  );
};

export default Status;
